var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        [
          _c("vab-icon", {
            attrs: { title: "需求中心", icon: ["fas", "bell"] },
          }),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("h3", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("消息通知"),
              ]),
              _c(
                "el-dropdown-item",
                { attrs: { disabled: true } },
                [
                  _c("el-empty", {
                    staticStyle: { width: "450px" },
                    attrs: { description: "暂无信息" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }