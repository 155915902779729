var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-admin-beautiful-wrapper", class: _vm.classObj },
    [
      "horizontal" === _vm.layout
        ? _c(
            "div",
            {
              staticClass: "layout-container-horizontal",
              class: {
                fixed: _vm.header === "fixed",
                "no-tabs-bar": _vm.tabsBar === "false" || _vm.tabsBar === false,
              },
            },
            [
              _c(
                "div",
                { class: _vm.header === "fixed" ? "fixed-header" : "" },
                [
                  _c("vab-top-bar"),
                  _vm.tabsBar === "true" || _vm.tabsBar === true
                    ? _c("div", { class: { "tag-view-show": _vm.tabsBar } }, [
                        _c(
                          "div",
                          { staticClass: "vab-main" },
                          [_c("vab-tabs-bar")],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vab-main main-padding" },
                [_c("vab-ad"), _c("vab-app-main")],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "layout-container-vertical",
              class: {
                fixed: _vm.header === "fixed",
                "no-tabs-bar": _vm.tabsBar === "false" || _vm.tabsBar === false,
              },
            },
            [
              _vm.device === "mobile" && _vm.collapse === false
                ? _c("div", {
                    staticClass: "mask",
                    on: { click: _vm.handleFoldSideBar },
                  })
                : _vm._e(),
              _c("vab-side-bar"),
              _c(
                "div",
                {
                  staticClass: "vab-main",
                  class: _vm.collapse ? "is-collapse-main" : "",
                },
                [
                  _c(
                    "div",
                    { class: _vm.header === "fixed" ? "fixed-header" : "" },
                    [
                      _c("vab-nav-bar"),
                      _vm.tabsBar === "true" || _vm.tabsBar === true
                        ? _c("vab-tabs-bar")
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("vab-ad"),
                  _c("vab-app-main"),
                ],
                1
              ),
            ],
            1
          ),
      _c("el-backtop"),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "切换企业",
            visible: _vm.showSwitchTab,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSwitchTab = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px", model: _vm.switchCompanyForm } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.switchCompanyForm.company_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.switchCompanyForm, "company_id", $$v)
                    },
                    expression: "switchCompanyForm.company_id",
                  },
                },
                _vm._l(_vm.companyList, function (item) {
                  return _c(
                    "el-form-item",
                    { key: item.id },
                    [
                      _c("el-radio", { attrs: { label: item.id } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSwitchCompany },
                    },
                    [_vm._v(" 提交 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showSwitchTab = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择后台",
            visible: _vm.showBackstage,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showBackstage = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "card-box" },
            _vm._l(_vm.backstageList, function (item, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "card-box-in",
                  on: {
                    click: function ($event) {
                      return _vm.handleSwitchBackstage(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-box-in-img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: { src: item.image_url, lazy: "" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "card-box-in-name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  Number(_vm.backstageId) === item.id
                    ? _c("div", { staticClass: "card-box-cur" }, [
                        _vm._v(" 目前 "),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }