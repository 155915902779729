<template>
  <div>
    <el-dropdown>
      <vab-icon title="需求中心" :icon="['fas', 'bell']" />

      <el-dropdown-menu slot="dropdown">
        <!-- 暂时注释 -->
        <h3 style="margin-left: 20px">消息通知</h3>
        <el-dropdown-item :disabled="true">
          <el-empty style="width: 450px" description="暂无信息"></el-empty>
          <!-- <div style="width: 450px">消息内容</div> -->
        </el-dropdown-item>
        <!-- <el-dropdown-item
          icon="el-icon-document"
          command="switchCompany"
          divided
        >
          我有需求
        </el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  export default {
    name: 'VabRemind',
    data() {
      return {}
    },
  }
</script>

<style></style>
