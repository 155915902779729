var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-breadcrumb",
    { staticClass: "breadcrumb-container", attrs: { separator: ">" } },
    _vm._l(_vm.list, function (item) {
      return _c("el-breadcrumb-item", { key: item.path }, [
        _vm._v(" " + _vm._s(item.meta.title) + " "),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }