var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vab-ad" },
    [
      _vm.adList
        ? _c(
            "el-carousel",
            {
              attrs: {
                height: "30px",
                direction: "vertical",
                autoplay: true,
                interval: 3000,
                "indicator-position": "none",
              },
            },
            _vm._l(_vm.adList, function (item, index) {
              return _c(
                "el-carousel-item",
                { key: index },
                [
                  _c("el-tag", { attrs: { type: "warning" } }, [_vm._v("Ad")]),
                  _c("a", { attrs: { target: "_blank", href: item.url } }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }