<template>
  <div>
    <el-dropdown @command="handleCommand">
      <span class="avatar-dropdown">
        <!--<el-avatar class="user-avatar" :src="avatar"></el-avatar>-->
        <!-- <img class="user-avatar" :src="avatar" alt="" /> -->
        <div class="user-name">
          {{ username + '（' + companyInfo['name'] + '）' }}
          <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <!-- :disabled="netConfig.baseURL !== 'https://api.t.paiplus.cn'" -->
        <!-- 暂时注释 -->
        <el-dropdown-item
          v-if="VUE_APP_TAG === 'paiplus'"
          command="switchBackstage"
          divided
        >
          切换后台
        </el-dropdown-item>
        <el-dropdown-item command="switchCompany" divided>
          切换企业
        </el-dropdown-item>
        <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { recordRoute } from '@/config'
  import netConfig from '@/config/net.config'

  export default {
    name: 'VabAvatar',
    inject: ['showSwitchTab', 'showBackstage'],
    data() {
      return {
        switchForm: {},
        baseUrl: '', // 测试开放，正式关闭
        VUE_APP_TAG: process.env.VUE_APP_TAG,
      }
    },
    computed: {
      ...mapGetters({
        avatar: 'user/avatar',
        username: 'user/username',
        companyInfo: 'user/companyInfo',
      }),
    },
    created() {
      this.baseUrl = netConfig.baseURL
    },
    methods: {
      handleCommand(command) {
        switch (command) {
          case 'logout':
            this.logout()
            break
          case 'switchCompany':
            this.switchCompany()
            break
          case 'switchBackstage':
            this.switchBackstage()
            break
        }
      },
      personalCenter() {
        this.$router.push('/personalCenter/personalCenter')
      },
      logout() {
        this.$baseConfirm(
          '您确定要退出' + this.$baseTitle + '吗?',
          null,
          async () => {
            await this.$store.dispatch('user/logout')
            if (recordRoute) {
              const fullPath = this.$route.fullPath
              this.$router.push(`/login?redirect=${fullPath}`)
            } else {
              this.$router.push('/login')
            }
          }
        )
      },
      switchCompany() {
        this.showSwitchTab()
      },
      switchBackstage() {
        this.showBackstage()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .avatar-dropdown {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: 50px;
    padding: 0;

    .user-avatar {
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 50%;
    }

    .user-name {
      position: relative;
      margin-left: 5px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
</style>
