<template>
  <div>
    <el-dropdown>
      <vab-icon title="需求中心" :icon="['fas', 'question-circle']" />

      <el-dropdown-menu slot="dropdown">
        <!-- 暂时注释 -->
        <el-dropdown-item icon="el-icon-question">帮助中心</el-dropdown-item>
        <el-dropdown-item
          icon="el-icon-document"
          command="switchCompany"
          divided
        >
          我有需求
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  export default {
    name: 'VabDemand',
    data() {
      return {}
    },
  }
</script>

<style></style>
