var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.routerView
    ? _c(
        "div",
        { staticClass: "app-main-container" },
        [
          _c(
            "transition",
            { attrs: { mode: "out-in", name: "fade-transform" } },
            [
              _c(
                "keep-alive",
                {
                  attrs: {
                    include: _vm.cachedRoutes,
                    max: _vm.keepAliveMaxNum,
                  },
                },
                [
                  _c("router-view", {
                    key: _vm.key,
                    staticClass: "app-main-height",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "footer",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.footerCopyright,
                  expression: "footerCopyright",
                },
              ],
              staticClass: "footer-copyright",
            },
            [
              _vm._v(" Copyright "),
              _c("vab-icon", { attrs: { icon: ["fas", "copyright"] } }),
              _vm._v(
                " vue-admin-better 开源免费版 " + _vm._s(_vm.fullYear) + " "
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }