var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        { on: { command: _vm.handleCommand } },
        [
          _c("span", { staticClass: "avatar-dropdown" }, [
            _c("div", { staticClass: "user-name" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.username + "（" + _vm.companyInfo["name"] + "）") +
                  " "
              ),
            ]),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _vm.VUE_APP_TAG === "paiplus"
                ? _c(
                    "el-dropdown-item",
                    { attrs: { command: "switchBackstage", divided: "" } },
                    [_vm._v(" 切换后台 ")]
                  )
                : _vm._e(),
              _c(
                "el-dropdown-item",
                { attrs: { command: "switchCompany", divided: "" } },
                [_vm._v(" 切换企业 ")]
              ),
              _c(
                "el-dropdown-item",
                { attrs: { command: "logout", divided: "" } },
                [_vm._v("退出登录")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }