var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        [
          _c("vab-icon", {
            attrs: { title: "需求中心", icon: ["fas", "question-circle"] },
          }),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { icon: "el-icon-question" } }, [
                _vm._v("帮助中心"),
              ]),
              _c(
                "el-dropdown-item",
                {
                  attrs: {
                    icon: "el-icon-document",
                    command: "switchCompany",
                    divided: "",
                  },
                },
                [_vm._v(" 我有需求 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }