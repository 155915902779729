var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-bar-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c("el-col", { attrs: { xs: 4, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c(
              "div",
              { staticClass: "left-panel" },
              [
                _c("i", {
                  staticClass: "fold-unfold",
                  class: _vm.collapse ? "el-icon-s-unfold" : "el-icon-s-fold",
                  attrs: { title: _vm.collapse ? "展开" : "收起" },
                  on: { click: _vm.handleCollapse },
                }),
                _c("vab-breadcrumb", { staticClass: "hidden-xs-only" }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { xs: 20, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c(
              "div",
              { staticClass: "right-panel" },
              [
                _c("vab-error-log"),
                _c("vab-full-screen-bar", {
                  on: { refresh: _vm.refreshRoute },
                }),
                _c("vab-theme-bar", { staticClass: "hidden-xs-only" }),
                _c("vab-icon", {
                  attrs: {
                    title: "重载所有路由",
                    pulse: _vm.pulse,
                    icon: ["fas", "redo"],
                  },
                  on: { click: _vm.refreshRoute },
                }),
                _c("vab-demand"),
                _c("vab-remind"),
                _c("vab-avatar"),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }